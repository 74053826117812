import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import PageWrapper from "../components/PageWrapper";
import * as styles from "../styles/modules/About.module.css"
import {IoIosArrowDown} from "react-icons/io"
import scrollTo from 'gatsby-plugin-smoothscroll';

const AboutPage = () => {
  return (
    <PageWrapper altLogo={true}>
      <section className={styles.aboutPage} >
        <StaticImage
          src="../images/about/cover.jpg"
          alt="Marianne Alliot dans son atelier"
          objectPosition="25% 50%"
        />
        <div className={styles.aboutSection} style={{overflowY: "scroll"}}>
          <hr style={{ border: 'none', width: "2ch", marginLeft: 0, height: '3px', backgroundColor: 'black' }} />
          <p>
            Marianne Alliot d’origine Aveyronaise vit et travaille à Nantes.
          </p>

          <p>
            « Ma peinture est nostalgique. Empreinte de souvenirs, à la plage
            l’été ou sur l’Aubrac de mon enfance, j’essaye de transformer ses
            instants en moments suspendus et sécurisants. Quelque soit le médium
            utilisé, le but de mon travail est de partager une émotion dictée
            par la lumière posée sur mon sujet. C’est plus fort que moi, je
            tente toujours de forcer la sensation de contre-jour, ou de
            surexposition. Ce qui me permet de révéler le détail et la sensation
            d’hyperréalisme .
          </p>

          <p>
            Je considère mon tableau fini quand je m’y sens installée dedans au
            calme, en toute quiétude »
          </p>

          <p>
            « Le travail de Marianne Alliot est universel, intemporel, comme les
            vagues qui ne finiront jamais, comme le soleil qui continuera à
            brunir nos peaux et réchauffer nos os, comme la neige qui fige à
            jamais nos âmes d’enfants. On s’y sent bien, on s’y retrouve »
          </p>

          <p style={{color: '#707070'}}>
            Bérengère Auvergnat - Artiste et Galeriste « la Mini Galerie » {" "}
          </p>
          <hr style={{ border:'none', width: "2ch", marginLeft: 0, height: '3px', backgroundColor: '#707070' }} />

          <p style={{color: '#B5B5B5'}}>
            Marianne Alliot is from the Aveyron region, she now lives and works
            in Nantes.
          </p>

          <p style={{color: '#B5B5B5'}}>
            My painting is nostalgic. Steeped in recollections of summer beaches
            or the snowy Aubrac of my childhood. I try to transform these
            memories into comforting, suspended moments. Regardless of the
            medium, my goal is to share an emotion created by the light landing
            on my subject. I can’t help myself, I always try to force the back
            lighting or the overexposure. This allows me to reveal the details
            and give a sense of hyperrealism.
          </p>

          <p style={{color: '#B5B5B5'}}>
            I consider a piece finished when I can enter in and find calm and
            quiet. 
          </p>

          <p style={{color: '#B5B5B5'}}>
            « Marianne Alliot’s work is universal, and timeless – in the same
            way the waves always wash onto the shore, just as the sun colours
            our skin and fills us with warmth, just as snow awakens the child
            within. In Marianne’s world we feel good. We feel at home. »
          </p>
        </div>
      </section>
      <section className={styles.mobileAboutPage} >
        <StaticImage
          src="../images/about/cover.jpg"
          alt="Marianne Alliot dans son atelier"
          style={{position: "fixed", top: 0, width: "100vw", height: "100vh"}}
          imgStyle={{objectFit: "cover", minHeight: "100vh", minWidth: "100vw"}}
        />
        <IoIosArrowDown style={{position: "fixed", top: "87vh", left: "4vw", color: "white"}} size={32} onClick={() => scrollTo("#Footer")}/>
        <p style={{position: "fixed", top: "90vh", left: "5vw", color: "white", fontSize: 10, fontWeight: "normal"}}>
              Marianne Alliot d’origine Aveyronaise vit et travaille à Nantes.
            </p>
        <div className={styles.aboutSection} style={{zIndex: 2,overflowY: "scroll"}}>
          
          <div style={{background: "transparent", padding: "5vw", paddingBottom: "15vw"}}>
            
            <p>
              « Ma peinture est nostalgique. Empreinte de souvenirs, à la plage
              l’été ou sur l’Aubrac de mon enfance, j’essaye de transformer ses
              instants en moments suspendus et sécurisants. Quelque soit le médium
              utilisé, le but de mon travail est de partager une émotion dictée
              par la lumière posée sur mon sujet. C’est plus fort que moi, je
              tente toujours de forcer la sensation de contre-jour, ou de
              surexposition. Ce qui me permet de révéler le détail et la sensation
              d’hyperréalisme .
            </p>
            <p>
              Je considère mon tableau fini quand je m’y sens installée dedans au
              calme, en toute quiétude »
            </p>
            <p>
              « Le travail de Marianne Alliot est universel, intemporel, comme les
              vagues qui ne finiront jamais, comme le soleil qui continuera à
              brunir nos peaux et réchauffer nos os, comme la neige qui fige à
              jamais nos âmes d’enfants. On s’y sent bien, on s’y retrouve »
            </p>
            <p style={{color: '#707070'}}>
              Bérengère Auvergnat - Artiste et Galeriste « la Mini Galerie » {" "}
            </p>
            <hr style={{ border:'none', width: "2ch", marginLeft: 0, height: '3px', backgroundColor: '#707070' }} />
            <p style={{color: '#B5B5B5'}}>
              Marianne Alliot is from the Aveyron region, she now lives and works
              in Nantes.
            </p>
            <p style={{color: '#B5B5B5'}}>
              My painting is nostalgic. Steeped in recollections of summer beaches
              or the snowy Aubrac of my childhood. I try to transform these
              memories into comforting, suspended moments. Regardless of the
              medium, my goal is to share an emotion created by the light landing
              on my subject. I can’t help myself, I always try to force the back
              lighting or the overexposure. This allows me to reveal the details
              and give a sense of hyperrealism.
            </p>
            <p style={{color: '#B5B5B5'}}>
              I consider a piece finished when I can enter in and find calm and
              quiet.
            </p>
            <p style={{color: '#B5B5B5'}}>
              « Marianne Alliot’s work is universal, and timeless – in the same
              way the waves always wash onto the shore, just as the sun colours
              our skin and fills us with warmth, just as snow awakens the child
              within. In Marianne’s world we feel good. We feel at home. »
            </p>
          </div>
        </div>
      </section>
    </PageWrapper>
  );
};

export default AboutPage;
